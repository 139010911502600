import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//component
import Seo from '../components/SEO'
import Pager from '../components/block/Pager'
//scss
import '../scss/page/blog.scss'
const TemplateBlog = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />
      <div className='blogPage'>
        <div className='container'>
          <div className='blog'>
            {data.c.nodes.map((item, index) => {
              return (
                <div key={index} className='blogPost'>
                  <Link to={`/blog/${item.slug}`}>
                    <GatsbyImage
                      image={item.img.gatsbyImageData}
                      alt={item.img.title}
                    />
                  </Link>
                  <div className='content'>
                    <h2 className='blockTitle'>
                      <Link to={`/blog/${item.slug}`}>{item.title}</Link>
                    </h2>
                    <p>{item.resume.resume}</p>
                    <Link to={`/blog/${item.slug}`} className='button'>
                      Lire la suite
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
          {pageContext.numPages > 1 ? (
            <Pager
              numPages={pageContext.numPages}
              currentPage={pageContext.currentPage}
              type='blog'
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    c: allContentfulBlog(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        slug
        resume {
          resume
        }
        img {
          title
          gatsbyImageData(
            width: 400
            height: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`

export default TemplateBlog
