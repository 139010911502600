import React from 'react'
import { Link } from 'gatsby'
//scss
import '../../scss/block/pager.scss'
const Pager = ({ numPages, currentPage, type }) => {
  return (
    <div className={`pager ${type}Pager`}>
      {Array.from({ length: numPages }, (_, i) =>
        numPages > 1 ? (
          <li key={`page-${i + 1}`}>
            <Link
              to={`/${i === 0 ? type : type + '/' + parseInt(i + 1)}`}
              className={`${currentPage === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </Link>
          </li>
        ) : (
          ''
        )
      )}
    </div>
  )
}

export default Pager
